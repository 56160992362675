import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
// import Login from './Components/login';
import RoomAvailable from './Components/roomsAvailable';
import ApproveBooking from './Components/approveBooking';
import MyBooking from './Components/myBookings';
import AdminBookingReq from './Components/adminBookingReq';
import ChangeDate from './Components/changeDate';
import BookingReports from './Components/bookingReports';
import NewLogin from './Components/newLogin';
import UserProfile from './Components/userProfile';
import Payments from './Components/payments';
function App() {

  return (
    <Provider store={store}>
      <Router basename="/">
      <Routes >
      <Route path="/" element={<NewLogin/>} />
        {/* <Route path="/" element={<Login/>} /> */}
        <Route
          path="/roomsAvailable"
          element={<RoomAvailable/>}
        />
        <Route path="/approveBooking" element={<ApproveBooking/>}></Route>
        <Route path='/myBookings' element={<MyBooking/>}></Route>
        <Route path='/adminBookingReq' element={<AdminBookingReq/>}></Route>
        <Route path='/changeDate' element={<ChangeDate/>} ></Route> 
        <Route path='/bookingReports' element={<BookingReports/>}></Route>    
        <Route path='/payments' element={<Payments/>}></Route>        
        <Route path='/userProfile' element={<UserProfile/>}></Route>   

      </Routes>
    </Router>
    </Provider>
  );

}
export default App;
