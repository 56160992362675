import React from "react";
import { useEffect, useState,useRef } from "react";
import moment from "moment";
import { message, DatePicker } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { decryptData } from "../Encryption/encrypt";
import NavBar from "./navBar";
const Payments = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: token
        }
    };
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const roomParam = params.get('user');
    const searchBar = useRef(null)
    const [user, setUser] = useState([]);
    const [searchQuery, setQuery] = useState('');
    const [userList, setUserList] = useState([]);
    const [bills, setBills] = useState([]);
    const [rcptDate, setDate] = useState('');
    const [selectedUser, setID] = useState([])
    const [TxnMode, setMode] = useState('');
    const[totalAmt,setTotalAmt] = useState(0)
    let timeout;
    useEffect(() => {
        if (roomParam) {
            const decodedRoom = decryptData(roomParam);
            setUser(decodedRoom)
            console.log(decodedRoom)
            setQuery(decodedRoom.Mobile)
            const data = {
                custName: decodedRoom.CustName,
                custID: decodedRoom.CustID
            }
            getPendingBills(data)
        }
    }, [roomParam]);    
    useEffect(() => {
        if (searchQuery) {
            clearTimeout(timeout);
            const data = {
                mobile: searchQuery
            }
            timeout = setTimeout(async () => {
                axios.post('http://rr.almasaarr.com:8008/Api/getSearchDetailsForPayments', data, config)
                    .then(res => {
                        setUserList(res.data)
                    }).catch(err => console.log(err))
            }, 500);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [searchQuery]);   
    const getTotal = ()=>{
        const result = bills.map(bill => {
            const amount = parseFloat(document.getElementById(`amount-${bill.BookigID}`).value) || 0;
            return {
                BookigID: bill.BookigID,
                Amount: amount
            };
        });
        const totalAmount = result.reduce((total, bill) => total + bill.Amount, 0);
        setTotalAmt(totalAmount)        
    }
    const getPendingBills = (user) => {
        setQuery(user.custName);
        setID(user)
        const data = {
            custID: user.custID
        }
        axios.post('http://rr.almasaarr.com:8008/Api/getBillList', data, config)
            .then(res => {
                const formattedList = res.data.map(item => {
                    return {
                        ...item,
                        ChkInDate: moment(item.ChkInDate).format('DD-MM-YYYY HH:mm'),
                        ChkOutDate: moment(item.ChkOutDate).format('DD-MM-YYYY HH:mm'),
                    };
                });
                setBills(formattedList)
            }).catch(err => console.log(err))
    };
    const handleDate = (date, dateString) => {
        setDate(dateString)
    }
    const buildJSON = () => {
        if (rcptDate !== "") {
            const result = bills.map(bill => {
                const amount = parseFloat(document.getElementById(`amount-${bill.BookigID}`).value) || 0;
                return {
                    BookigID: bill.BookigID,
                    Amount: amount
                };
            });
            const data = {
                rcptDate,
                pack: JSON.stringify(result),
                mode: TxnMode,
                custID: selectedUser.custID
            }
            axios.post('http://rr.almasaarr.com:8008/Api/markBills', data, config)
                .then(res => {
                    if (res.status == 200) {
                        message.success(res.data.message);
                        getPendingBills(selectedUser)
                    } else {
                        message.error(res.data.message);
                    }
                }).catch(err => {
                    message.error("Something went wrong!");
                })
        } else {
            message.error("Enter date")
        }
    };
    const newTxn = () =>{
        setUser([])
        setQuery([])
        setUserList([])
        setBills([])
        setID([])
        setMode('');
        searchBar.current.focus();
    }
    return (
        <>
            <NavBar />
            <div className="container">
                <div className="row mt-2">
                    <div className="col-lg-12">
                        <h3>Payments</h3>
                        <div className="card">
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-lg-7">
                                        <div className="form-floating">
                                            <input
                                                className="form-control"
                                                type="text"
                                                onChange={e => setQuery(e.target.value)}
                                                value={searchQuery}
                                                ref={searchBar}
                                                placeholder="Search..."
                                            />
                                            <label>Search by name/mobile</label>
                                        </div>
                                        {userList.length > 1 && (
                                            <>
                                                <div className="list-group" style={{ maxHeight: '150px', overflowY: 'scroll' }}>
                                                    {userList.map((users) => (
                                                        <a
                                                            key={users.custID}
                                                            className={`list-group-item list-group-item-action`}
                                                            onClick={() => getPendingBills(users)}
                                                            href="#"
                                                        >
                                                            {users.custName}
                                                        </a>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                        <h3 className="text-center">Pending Bills</h3>
                                        <div className="col-lg-12 mt-2 mb-2 d-inline-flex gap-2">
                                            <DatePicker
                                                format='YYYY-MM-DD'
                                                onChange={handleDate}

                                            />
                                            <input className="form-control" placeholder="Mode Of payment" onChange={e => setMode(e.target.value)} />
                                        </div>
                                        <div>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            ID
                                                        </th>
                                                        <th>
                                                            Room no
                                                        </th>
                                                        <th>
                                                            Chk-In
                                                        </th>
                                                        <th>
                                                            Chk-Out
                                                        </th>
                                                        <th>
                                                            Total Amount
                                                        </th>
                                                        <th>
                                                            Paid
                                                        </th>
                                                        <th>
                                                            Balance
                                                        </th>
                                                        <th>
                                                            Enter Amount
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bills.map((bill, index) => (
                                                        <tr key={index}>
                                                            <td>{bill.BookigID}</td>
                                                            <td>{bill.RoomNo}</td>
                                                            <td>{bill.ChkInDate}</td>
                                                            <td>{bill.ChkOutDate}</td>
                                                            <td>{bill.AmtEstimate}</td>
                                                            <td>{bill.Paid}</td>
                                                            <td>{bill.Balance}</td>
                                                            <td>
                                                                <input onChange={getTotal} id={`amount-${bill.BookigID}`} className="form-control" placeholder="Amount" defaultValue={0} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="col-lg-12 mt-2 justify-content-end d-flex gap-2 align-items-center d-flex flex-wrap">
                                                <span>
                                                    Total Amount Entered : {totalAmt}
                                                </span>
                                                <button type="button" className="btn btn-primary" onClick={newTxn}>new</button>
                                                <button className="btn btn-success" type="button" onClick={buildJSON}>Ok</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Payments;