import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
const AdminNavBar = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: token
        }
    };
    const [user, SetUser] = useState('');
    useEffect(() => {
        chkUser();

    }, []);
    const navigate = useNavigate();
    function navigateTo(component) {
        navigate(component);
    }
    async function chkUser() {
        try {
            const responce = axios.get(`${process.env.REACT_APP_API_URL}/Api/chkUserType`, config);
            if ((await responce).status === 200) {

                SetUser("superadmin");
            } else if ((await responce).status === 300) {
                SetUser("admin");
            } else {
                SetUser("Gen");
            }
        } catch (error) {
            console.log("Error Occured")
        }
    }
    return (
        <>
            {
                user === "superadmin" ?
                    (
                        <>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => navigateTo("/adminBookingReq")}>Add bookings</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => navigateTo("/approveBooking")}>Manage bookings</a>
                            </li>
                            <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="adminDp" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Reports
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="/bookingReports">Booking reports</a>  
                                <a className="dropdown-item" href="/payments">Payments</a>                                      
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#">Reports</a>
                            </div>
                        </li>
                        </>
                    ) :
                    (
                        <div>

                        </div>
                    )
            }
        </>
    )
}
export default AdminNavBar;